<!--
 * @Descripttion: 入驻机构
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-05-29 14:28:55
-->
<template>
  <div class="bg vh">
    <van-nav-bar title="入驻机构" left-arrow @click-left="$router.push('/thanks')"/>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      class="pad34"
    >
      <ul>
        <li v-for="(item,index) in list" :key="index" class="org">
          <img :src="logo(item.logo)">
          <div>
            <h3>{{ item.name }}</h3>
            <p>{{ item.create_time }}</p>
          </div>
        </li>
      </ul>
    </van-list>
  </div>
</template>

<script>
import {List} from 'vant'
export default {
 data(){
    return {
      loading: false,
      finished: false,
      list: [],
      params: {
        pageNo: 1,
        pageSize: 10,
      },
      testData : []
    }
  },
  components: {
    [List.name]:List
  },

  computed:{
    logo(){
      return logo => logo? logo : '/static/img/icon/logo.png'
    }
  },

  methods: {
    onLoad(){
      this.$ajax({
        url: '/gateway/ed/thanks/tbEdThanks/settledOrgList',
        params: this.params,
        header: { "X-Access-Token": this.$ls.get('token') }
      }).then( ({data}) => {
        if(data.success){
          this.loading = false
          this.list = this.list.concat(data.result.result)
          if(this.list.length >= data.result.total){
            this.finished = true
            return
          }
          this.params.pageNo++
        }
      }).catch( err => console.log(err))
    }
  }
}
</script>

<style scoped>
  .org {
    position: relative;
    padding: .32rem .21rem;
    border-radius: 0.2rem;
    color: #1e1e1e;
    display: flex;
    background-color: #fff;
  }
  .org:not(:first-child){margin-top: .32rem;}
  .org img {width: 1.17rem; height: 1.17rem; object-fit: cover;}
  .org >div {padding-left: .21rem;}
  .org h3 {font-size: .43rem; line-height: .59rem; }
  .org p {font-size: .35rem; line-height: .48rem; margin-top: .11rem;}
  .pad34{padding: .32rem .43rem;}
</style>